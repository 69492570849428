import React from 'react';
import './index.scss';

const HomePage = () => {
  const publicWikiUrl = process.env.PUBLIC_WIKI_URL;
  const privateWikiUrl = process.env.PRIVATE_WIKI_URL;
  return (
    <div className="home-page wrapper-1180">
      <div className="home-page__header shadow-block">
        <h2>Home</h2>
      </div>
      <div className="home-page__separator" />
      <table className="home-page__table">
        <thead>
          <tr>
            <th className="home-page__table-cell">Name</th>
            <th className="home-page__table-cell">Link</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="home-page__table-cell">Public</td>
            <td className="home-page__table-cell"><a href={publicWikiUrl}>{publicWikiUrl}</a></td>
          </tr>
          <tr>
            <td className="home-page__table-cell">Private</td>
            <td className="home-page__table-cell"><a href={privateWikiUrl}>{privateWikiUrl}</a></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default HomePage;
