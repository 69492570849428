import { useMemo } from 'react';
import find from 'lodash/find';
import { v4 as uuidv4 } from 'uuid';

import ScrollBlocker, { ContentScrollBlocker } from './ScrollBlocker';

interface EnhancedScrollBlocker extends ContentScrollBlocker {
  id: string;
  isBlockedBy?: string;
  isBlockingContentScroll: boolean;
  remove(): void;
}

export interface EnhancedScrollBlockerOptions {
  rootNode: HTMLElement;
}

const getEnhancedScrollBlocker = (() => {
  const scrollBlockers: { [id: string]: EnhancedScrollBlocker } = {};

  return ({ rootNode }: EnhancedScrollBlockerOptions) => {
    const id = uuidv4();

    let scrollBlocker: ScrollBlocker;

    return scrollBlockers[id] = {
      id,
      isBlockingContentScroll: false,
      blockContentScroll() {
        const previouslyBlocked = find(scrollBlockers, scrollBlocker => (
          scrollBlocker.isBlockingContentScroll && !scrollBlocker.isBlockedBy
        ));

        scrollBlocker = new ScrollBlocker(
          previouslyBlocked?.id ? document.getElementById(previouslyBlocked.id) : rootNode
        );

        scrollBlockers[id].isBlockingContentScroll = true;
        scrollBlocker?.blockContentScroll();

        if (previouslyBlocked) {
          previouslyBlocked.isBlockedBy = id;
        }
      },
      enableContentScroll() {
        if (scrollBlockers[id]?.isBlockingContentScroll) {
          const previouslyBlocked = find(scrollBlockers, scrollBlocker => (
            scrollBlocker.isBlockingContentScroll && scrollBlocker.isBlockedBy === id
          ));

          scrollBlockers[id].isBlockingContentScroll = false;
          scrollBlocker.enableContentScroll();

          if (previouslyBlocked) {
            previouslyBlocked.isBlockedBy = undefined;
          }
        }
      },
      remove() {
        delete scrollBlockers[id];
      },
    };
  };
})();

export default function useEnhancedScrollBlocker(options: EnhancedScrollBlockerOptions) {
  return useMemo(() => getEnhancedScrollBlocker(options), [options]);
}
