import PopUp from '../../PopUp';
import React from 'react';
import {ReactSVG} from 'react-svg';
import crossIcon from '../../../../assets/icon-cross-gray.svg';

import './PopupImage.scss';

type PopupImage = {
  imageSrc: string,
  onHide: () => void,
  visible: boolean,
}

const PopupImage = (props: PopupImage) => {
  const  {imageSrc, onHide, visible} = props;

  return (
    <PopUp onHide={onHide} scrollToTop={visible} showCloseIcon={false} visible={visible}>
      <div className="popup__img-container" data-testid="popup-image-container">
        <div className="popup__img-wrapper">
          <div className="popup__cross-icon-container" onClick={onHide} data-testid="popup-cross-icon">
            <ReactSVG className="popup__cross-icon" src={crossIcon}/>
          </div>
          <img src={imageSrc}/>
        </div>
      </div>
    </PopUp>
  );
}

export default PopupImage;
