import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';

import defaultIcon from '../../../assets/icon-success.svg';
import Tooltip from '../Tooltip';
import './index.scss';

/**
 * The component implements UI of different tools in 3d viewer.
 * The component includes "hint" represented with a Tooltip optionally.
 * Can be used with icons only.
 */
const ToolButton = (props) => {
  const {
    checked,
    className,
    disabled,
    hint,
    hintPosition,
    icon,
    onClick,
  } = props;

  const classes = classNames(
    'tool-button',
    {
      checked,
      disabled,
    },
  );

  const renderButton = () => {
    return (
      <button className={classes} onClick={onClick}>
        <ReactSVG className="tool-button--icon" src={icon}/>
      </button>
    );
  };

  const renderWithTooltip = () => {
    return (
      <Tooltip disabled={disabled} msg={hint} position={hintPosition}>
        {renderButton()}
      </Tooltip>
    );
  };

  const wrapperClasses = classNames({
    'tool-button--wrapper': true,
    [className]: className,
  });
  return (
    <div className={wrapperClasses}>
      {hint? renderWithTooltip() : renderButton()}
    </div>);
};

ToolButton.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hint: PropTypes.string,
  hintPosition: PropTypes.oneOf(['top', 'right', 'left', 'bottom']),
  icon: PropTypes.node,
  onClick: PropTypes.func.isRequired,
};

ToolButton.defaultProps = {
  checked: false,
  disabled: false,
  hintPosition: 'right',
  icon: defaultIcon,
};

export default ToolButton;
