// eslint-disable-next-line complexity
export const parseAPIError = (error) => {
  const response = error && error.response;
  if (!response) {
    return {
      response: 'Server is not responding',
    };
  }
  return {
    response,
    statusCode: response.status,
    statusText: response.statusText,
    data: response.data || {},
    nonFieldErrors: response.data && response.data['non_field_errors'] || [],
  };
};
