import React, { ReactNode, useMemo } from 'react';

import TopPanelSide from './TopPanelSide';
import TopPanelLogo from '../../../app/TopPanel/TopPanel/TopPanelLogo';

import '../index.scss';

export interface TopPanelCenterPropTypes {
  children?: ReactNode;
  isLogo?: boolean;
  ergeonUrl?: string;
}

const TopPanelCenter = (props: TopPanelCenterPropTypes) => {
  const { isLogo, children, ergeonUrl = 'https://www.ergeon.com/' } = props;

  const ergeonLogo = useMemo(() => <TopPanelLogo ergeonHomePage={ergeonUrl} />, [ergeonUrl])

  return (
    <TopPanelSide placement="center">
      {isLogo ? ergeonLogo : children}
    </TopPanelSide>
  )
};

export { TopPanelCenter };
