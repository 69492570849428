import ErgAuthService from '@ergeon/erg-auth';

const COOKIE_PARAM_NAME = 'cookieName';
const OPTIONAL_SETTINGS = {};
if (process.env.AUTH_COOKIE_NAME) {
  OPTIONAL_SETTINGS[COOKIE_PARAM_NAME] = process.env.AUTH_COOKIE_NAME;
}

export const authService = new ErgAuthService({
  ...OPTIONAL_SETTINGS,
  appId: 'ergeon',
  idService: process.env.ID_SERVICE,
  userDetailsEndpoint: `${process.env.API_HOST}/api/v1/authenticate/help/`,
  level: process.env.LEVEL || 'development',
  mockJWT: process.env.MOCK_JWT,
});

export default authService;
