import React from 'react';

import PropTypes from 'prop-types';
import { Highlighter } from '@ergeon/core-components';

import HomeIcon from 'assets/icon-home-black.svg';
import { isSameString } from 'utils/utils';

import HelpLink from '../HelpPage/HelpLink';
import './index.scss';

class HelpSearchResults extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    results: PropTypes.arrayOf(PropTypes.object),
    search: PropTypes.string,
    isRootPath: PropTypes.bool,
  };

  redirectToPath(path) {
    const { history } = this.props;

    if (path) history.push({
      pathname: path,
    });
  }

  renderResult({ nodeKey, title, shortMemo, breadcrumb }) {
    const { search, isRootPath } = this.props;
    const description = (isSameString(title, shortMemo) ? '' : shortMemo) || '';
    return (
      <div className="search-result" key={`result-${nodeKey}`}>
        <HelpLink
          nodeKey={nodeKey}
          redirectToPath={this.redirectToPath.bind(this)}
          isRootPath={isRootPath}
        >
          <span className="search-result__title">
            <Highlighter searchText={search}>
              {title}
            </Highlighter>
            <div className="search-result__description">

              <Highlighter searchText={search}>
                {description}
              </Highlighter>
            </div>
          </span>
        </HelpLink>
        <div className="search-result__breadcrumb">
          <span className="breadcrumb-item">
            <span onClick={
              this.redirectToPath.bind(this, isRootPath ? '/' : '/help')
            }><img src={HomeIcon} /></span>
          </span>
          {[...breadcrumb].reverse().slice(1).map(item => (
            <span className="breadcrumb-item" key={`result-${nodeKey}-${item.nodeKey}`}>
              <span onClick={
                this.redirectToPath.bind(this, isRootPath ? `/${item.nodeKey}` : `/help/${item.nodeKey}`)
              }>{item.title}</span>
            </span>
          ))}
        </div>
      </div >
    );
  }

  renderDefaultResult() {
    return (
      <div className="search-result">
        <span>Your search returned no matches.</span>
      </div>
    );
  }

  render() {
    const { results } = this.props;

    return (
      <div className="search-results">
        {results.map(this.renderResult.bind(this))}
        {!results.length && this.renderDefaultResult()}
      </div>
    );
  }
}

export default HelpSearchResults;
