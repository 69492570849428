import React, { HTMLAttributes } from 'react';

import Title from '../Title';
import { ImageGalleryImage } from '.';

type ImageGalleryTitleProps = {
  title?: string,
  titleIcon?: string,
  images: ImageGalleryImage[],
} & HTMLAttributes<HTMLDivElement>

const ImageGalleryTitle = ({ title, titleIcon, images, ...divAttr }: ImageGalleryTitleProps) => {
  if (!title) return null;
  return (
    <Title {...divAttr} className="link" icon={titleIcon}>
      <a className="link" href="">
        {title} ({images.length})
      </a>
    </Title>
  )
}

export default ImageGalleryTitle;
