import React, { useCallback, useMemo } from 'react';

import PropTypes from 'prop-types';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';

/**
 * Creates a smart <a>-component that is able to
 * - call the `redirectToPath` prop with the History API used inside (for smooth redirects),
 * - and handle ”open in a new tab” scenario.
 *
 * @param props
 */
const HelpLink = (props) => {
  const { className, children, nodeKey, redirectToPath } = props;

  /**
   * Returns /help(/[nodeKey]) to be used in a link <a> src attrib.
   */
  const linkPath = useMemo(() => {
    const baseHelpPath = '/help';
    if (!nodeKey) {
      return baseHelpPath;
    }
    return `${baseHelpPath}/${nodeKey}`;
  }, [nodeKey]);

  /**
   * If any special key is pressed omit history handler. This helps to process shift + ctrl/cmd
   * cases that could be treated as the ”open in a new tab” scenario by a browser.
   */
  const onClick = useCallback((e) => {
    e?.preventDefault();
    if (isEmpty(compact([e.shiftKey, e.altKey, e.ctrlKey, e.metaKey]))) {
      redirectToPath(linkPath);
    }
  }, [redirectToPath]);

  return (
    <a href={linkPath} {...{ className, onClick }}>{children}</a>
  );
};

HelpLink.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  nodeKey: PropTypes.string,
  redirectToPath: PropTypes.func.isRequired,
};

export default HelpLink;
