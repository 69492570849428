import React, {Children, HTMLAttributes, ReactElement, ReactNode, useEffect, useState} from 'react';
import SwiperCore from 'swiper/core';
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';
import noop from 'lodash/noop';

import {setTransition} from '../SwipeableNav/helpers';

type SwiperType = Omit<
  HTMLAttributes<HTMLElement>,
  | "onProgress"
  | "onClick"
  | "onTouchEnd"
  | "onTouchMove"
  | "onTouchStart"
  | "onTransitionEnd"
  | "onKeyPress"
  | "onDoubleClick"
  | "onScroll"
>;

interface SwipeableContentProps extends SwiperType {
  allowTouchMove?: boolean,
  children: ReactNode,
  activeSlideIdx: number,
  onSlideChange(i: number): void,
  // Expose Swiper methods for more fine tuning
  onProgress?: Swiper['onProgress'],
  onSetTransition?: Swiper['onSetTransition'],
  // turn on/off isActive slide rendering
  isActiveRender?: boolean,
};

/**
 * Swipe-able views. Controlled component.
 *
 * @param props
 * @returns
 */
function SwipeableContent(props: SwipeableContentProps) {
  const {
    activeSlideIdx,
    allowTouchMove,
    children,
    onProgress = noop,
    onSetTransition = noop,
    onSlideChange,
    isActiveRender = false,
    ...ElementAttr
  } = props;

  // Swiper lib is used to achieve swipe-able behavior.
  const [navSwRef, setNavSwRef] = useState<SwiperClass>();

  useEffect(function setActiveIdx() {
    if (navSwRef?.activeIndex !== activeSlideIdx) {
      navSwRef?.slideTo(activeSlideIdx, SwiperCore.defaults.speed);
    }
  }, [activeSlideIdx, navSwRef])

  return (
    <Swiper
      {...ElementAttr}
      allowTouchMove={allowTouchMove}
      onActiveIndexChange={({activeIndex}) => {
        onSlideChange(activeIndex);
      }}
      onProgress={onProgress}
      onSetTransition={onSetTransition}
      onSwiper={setNavSwRef}>
      {Children.map(children, (child, idx) => (
        <SwiperSlide key={(child as ReactElement)?.key ?? idx}>
          {({ isActive }) => (
            <>
              {/* isActive is only true on the current visible slide, so when we have many we only
              render child on the active one, if isActiveRender = false, then we will render
              each child on each slide */}
              {isActiveRender && isActive ? child : null}
              {!isActiveRender && child}
            </>
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

SwipeableContent.setTransition = setTransition;

export default SwipeableContent;
