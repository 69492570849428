import React, { ReactNode, useMemo } from 'react';

import ErgeonLink from '../../base/ErgeonLink';

import { getEnvName } from './utils';
import { TERMS_OF_USE_URL, PRIVACY_POLICY_URL } from '../../../constants';

import './TermsAndPolicy.scss';

type TermsAndPolicyProps = {
  appEnv?: string;
  agreementText?: string | ReactNode
};

const TermsAndPolicy = ({ appEnv, agreementText }: TermsAndPolicyProps) => {
  const envName = useMemo(() => {
    return getEnvName(appEnv);
  }, [appEnv, getEnvName]);

  return (
    <div className='terms-and-policy'>
      <div className='copyright'>
        &copy; {new Date().getFullYear()} Ergeon Inc
      </div>

      {agreementText && agreementText}

      <ErgeonLink className='terms-link' href={TERMS_OF_USE_URL[envName]}>
        Terms of Use
      </ErgeonLink>
      &nbsp;&bull;&nbsp;
      <ErgeonLink className='terms-link' href={PRIVACY_POLICY_URL[envName]}>
        Privacy Policy
      </ErgeonLink>
    </div>
  );
};

export default TermsAndPolicy;
