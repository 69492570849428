import axios from 'axios';
import humps from 'lodash-humps';

import {
  DEFAULT_DOMAIN_ID,
  DEFAULT_HELP_ROOT_NODE,
  HELP_NODE_PATH,
  FEEDBACK_PATH,
  PRIVATE_DOMAIN_ID,
} from 'utils/constants';

const getHeaders = (domain = DEFAULT_DOMAIN_ID, auth) => {
  const headers = {
    'Content-Type': 'application/json',
  };

  if (domain === PRIVATE_DOMAIN_ID && auth && auth.getAuthHeader) {
    headers['Authorization'] = auth.getAuthHeader();
  }

  return headers;
};

export const getHelpNode = ({
  apiHost,
  auth,
  domain = DEFAULT_DOMAIN_ID,
  nodeKey = DEFAULT_HELP_ROOT_NODE,
}) => {
  const path = `${HELP_NODE_PATH}/${nodeKey}/?domain=${domain}`;

  return axios({
    method: 'get',
    url: apiHost + path,
    data: JSON.stringify(nodeKey),
    responseType: 'json',
    headers: getHeaders(domain, auth),
    transformResponse: [(data) => {
      return humps(data);
    }],
  }).then(({ data }) => data);
};

export const getGlobalHelpResults = ({
  apiHost,
  auth,
  domain = DEFAULT_DOMAIN_ID,
  nodeKey,
}) => {
  const path = `${HELP_NODE_PATH}/?search=${nodeKey}`;

  return axios({
    method: 'get',
    url: apiHost + path,
    data: JSON.stringify(nodeKey),
    responseType: 'json',
    headers: getHeaders(domain, auth),
    transformResponse: [(data) => {
      return humps(data);
    }],
  });
};

export const getLocalHelpResults = ({
  apiHost,
  auth,
  domain = DEFAULT_DOMAIN_ID,
  searchTerm,
  nodeKey,
}) => {
  const path = `${HELP_NODE_PATH}/?selected_node=${nodeKey}&search=${searchTerm}`;

  return axios({
    method: 'get',
    url: apiHost + path,
    data: JSON.stringify(nodeKey),
    responseType: 'json',
    headers: getHeaders(domain, auth),
    transformResponse: [(data) => {
      return humps(data);
    }],
  });
};

export const submitFeedback = ({
  apiHost,
  auth,
  domain = DEFAULT_DOMAIN_ID,
  nodeId,
  content,
}) => {
  return axios({
    method: 'post',
    url: apiHost + FEEDBACK_PATH,
    data: {
      node: nodeId,
      content,
    },
    headers: getHeaders(domain, auth),
  });
};
