import React, { FormEvent, useState, useCallback, ChangeEvent, HTMLAttributes } from 'react';
import { ImageListType } from "react-images-uploading";
import Input from '../Input';
import Button from '../../atoms/Button';
import Spinner from '../Spinner';
import Notification from '../Notification';

import './FeedbackForm.scss';
import UploadArea from './UploadArea';

export type FeedbackFormProps = {
  placeholder: string,
  onSubmit: ({ images, resetImages }: { images: ImageListType, resetImages: () => void }) => void,
  onChange: (event: ChangeEvent) => void,
  isLoading: boolean,
  isSuccess: boolean,
  successMsg: string,
  maxUploads: number,
  value: string,
} & Omit<HTMLAttributes<HTMLDivElement>, 'onSubmit' | 'onChange' | 'placeholder'>;

const FeedBackForm = (props: FeedbackFormProps) => {
  const { placeholder, onSubmit, onChange, isLoading, isSuccess, successMsg, value, maxUploads = 4, ...divAttr } = props;
  const [images, setImages] = useState<ImageListType>([]);

  const onChangeFile = (imageList: ImageListType) => {
    setImages(imageList);
  };

  const resetImages = () => {
    setImages([]);
  };

  const onSubmitForm = useCallback((e: FormEvent) => {
    e.preventDefault();
    onSubmit({ images, resetImages });
  }, [images, onSubmit]);

  return (
    <div {...divAttr} className="FeedbackForm">
      {isSuccess &&
        <Notification mode="embed">
          {successMsg}
        </Notification>
      }
      {!isSuccess &&
        <div className="FeedbackForm-content">
          {isLoading && images.length > 0 &&
            <div className="FeedbackForm-loadingWrapper">
              <Spinner active={true} color="blue" borderWidth={0.10} size={16} />
              <p>Uploading data...</p>
            </div>
          }
          <form className='FeedbackForm-Form' onSubmit={onSubmitForm}>
            <div className="FeedbackForm-wrapper">
              <Input
                className="FeedbackForm-TextArea"
                name="feedback"
                onChange={(event) => onChange(event)}
                placeholder={placeholder}
                isMultiline
                isDisabled={isLoading}
                value={value} />
            </div>
            <Button
              className="FeedbackForm-Button"
              flavor="primary"
              size="medium"
              disabled={isLoading}>
              {isLoading
                ? <Spinner active={true} borderWidth={0.10} size={25} />
                : 'Submit'}
            </Button>
          </form>
          <UploadArea onChange={onChangeFile} maxUploads={maxUploads} imagesList={images} />
        </div>
      }
    </div>
  );
};

export default FeedBackForm;
