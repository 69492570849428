import React, { HTMLAttributes, ReactNode,  useState, useRef, useEffect, useCallback } from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.scss';

type TooltipProps = {
    children: ReactNode;
    msg: string | ReactNode;
    position: 'top' | 'right'| 'left'| 'bottom';
    className?: string;
    disabled?: boolean;
    onClick?(event: MouseEvent): void,
  } & HTMLAttributes<HTMLDivElement>

/**
 * The component represents a UI of tooltips.
 * Use as a wrapper of any node which requires a hint.
 */
const Tooltip = (props: TooltipProps) => {
  const { children, msg, position, className = '', disabled, onClick } = props;
  const tooltipRef = useRef<HTMLDivElement>(null);
  const targetRef = useRef<HTMLSpanElement>(null);
  const [shown, setShown] = useState<boolean>(false);
  const [tooltipRect, setTooltipRect] = useState<DOMRect| null>(null);
  const [targetRect, setTargetRect] = useState<DOMRect| null>(null);

  const classes = classNames({
    'tooltip': true,
    disabled,
    shown,
    [position]: true,
    [className]: className,
  });

  const hideTooltip = useCallback(() => {
    if (!disabled) {
      setShown(false);
    }
  }, [disabled, setShown]);

  useEffect(function onTooltipShown() {
    setTooltipRect(tooltipRef.current!.getBoundingClientRect());
    setTargetRect(targetRef.current!.getBoundingClientRect());
  }, [shown]);

  useEffect(
    function handleOnScroll() {
      if (shown) {
        window.addEventListener('scroll', hideTooltip);
      } else {
        window.removeEventListener('scroll', hideTooltip);
      }

      return () => {
        window.removeEventListener('scroll', hideTooltip);
      };
    },
    [hideTooltip, shown],
  );

  const setPosition = useCallback((x, y) => {
    x = Math.round(x);
    y = Math.round(y);
    tooltipRef.current!.style.cssText = `left: ${x}px; top: ${y}px;`;
  }, [tooltipRef]);

  // eslint-disable-next-line complexity
  useEffect(function calculateTooltipPosition() {
    if (shown && tooltipRect && targetRect) {
      const gap = 6; // A gap between tooltip and the target node
      switch (position) {
        case 'top': {
          const x = targetRect.x + targetRect.width / 2 - tooltipRect.width / 2;
          const y = targetRect.y - tooltipRect.height - gap;
          setPosition(x, y);
          break;
        }
        case 'right': {
          const x = targetRect.x + targetRect.width + gap;
          const y = targetRect.y + targetRect.height / 2 - tooltipRect.height / 2;
          setPosition(x, y);
          break;
        }
        case 'left': {
          const x = targetRect.x - tooltipRect.width - gap;
          const y = targetRect.y + targetRect.height / 2 - tooltipRect.height / 2;
          setPosition(x, y);
          break;
        }
        case 'bottom': {
          const x = targetRect.x + targetRect.width / 2 - tooltipRect.width / 2;
          const y = targetRect.y + targetRect.height + gap;
          setPosition(x, y);
          break;
        }
      }
    }
  }, [tooltipRect, targetRect, position, setPosition, shown]);

  const showTooltip = useCallback(() => {
    if (!disabled) {
      setShown(true);
    }
  }, [disabled, setShown]);

  return (
    <span
      className="tooltip--wrapper"
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      onTouchEnd={showTooltip}
      onClick={onClick || hideTooltip}
      ref={targetRef}>
      {children}
      <div className={classes} ref={tooltipRef}>
        {msg}
      </div>
    </span>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  msg: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  position: PropTypes.oneOf(['top', 'right', 'left', 'bottom']),
  onClick: PropTypes.func,
};

Tooltip.defaultProps = {
  position: 'top',
};

export default Tooltip;
