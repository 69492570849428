import React from 'react';

import PropTypes from 'prop-types';

import { getParameterByName } from 'utils/utils';
import HelpSearchField from 'components/HelpSearchField';
import './index.scss';

class HelpLandingPage extends React.Component {

  static propTypes = {
    categories: PropTypes.arrayOf(PropTypes.shape({
      icon: PropTypes.node,
      nodeKey: PropTypes.string,
      title: PropTypes.string,
    })).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    isLargeIcons: PropTypes.bool,
    subtitle: PropTypes.node,
    title: PropTypes.node,
    isRootPath: PropTypes.bool,
  };

  componentDidMount() {
    const nodeKey = getParameterByName('node_key');
    const { history, isRootPath } = this.props;

    if (nodeKey) {
      const pathname = isRootPath ? `/${nodeKey}` : `/help/${nodeKey}`;
      history.push({
        pathname,
      });
    }
  }

  handleRedirect(path) {
    const { history } = this.props;

    if (history) {
      history.push({ pathname: path });
    }
  }

  searchRedirect(value) {
    const { isRootPath } = this.props;
    if (value) {
      const pathname = isRootPath ? '/search' : '/help/search';
      this.props.history.push({
        pathname,
        search: `?q=${value}`,
      });
    }
  }

  getCategoryIconClass() {
    return this.props.isLargeIcons ? 'category-icon-large' : 'category-icon-medium';
  }

  render() {
    const { categories, subtitle, title, isRootPath } = this.props;

    return (
      <div className="help-landing-page">
        <div className="shadow-block">
          <div className="help-landing-page__header wrapper-1180">
            <div>
              <h1 className="h2">{title}</h1>
              {subtitle && (
                <div className="subheader h2 spacing before__is-6">
                  {subtitle}
                </div>
              )}
              <div className="spacing before__is-24 search-field-container">
                <HelpSearchField onSubmit={this.searchRedirect.bind(this)} />
              </div>
            </div>
          </div>
        </div>
        <div className="help-landing-page__body wrapper-1180">
          <div className="help-landing-page__categories">
            {categories.map(category => (
              <div key={category.nodeKey}
                onClick={() =>
                  this.handleRedirect(isRootPath ? `/${category.nodeKey}` : `/help/${category.nodeKey}`)
                }
                className={`category__${category.nodeKey}`}
              >
                <div
                  className="help-landing-page__category">
                  <img className={this.getCategoryIconClass()} src={category.icon} />
                  <span className="additional-header h2">{category.title}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default HelpLandingPage;
