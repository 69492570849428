import React, { HTMLAttributes, useMemo } from 'react';

import classNames from 'classnames';
import OptimizedImage from '../OptimizedImage';

import './index.scss';

export type SealProps = {
  imageUrl: string;
  linkUrl?: string;
  linkTarget?: string;
  additionalClassName?: string;
  text: string;
  helperText: string;
};

const Seal = (props: SealProps & HTMLAttributes<HTMLDivElement | HTMLAnchorElement>) => {
  const {
    text,
    helperText,
    imageUrl,
    linkUrl,
    linkTarget = 'blank',
    additionalClassName,
    ...elementAttr
  } = props;

  const WrapperElement = useMemo(() => {
    if (linkUrl) {
      return 'a';
    }
    return 'div';
  }, [linkUrl]);

  const wrapperClassName = useMemo(() => {
    return classNames('seal-wrapper', additionalClassName);
  }, [additionalClassName]);

  const anchorAttr = elementAttr as HTMLAttributes<HTMLElement> 

  return (
    <WrapperElement
      { ...anchorAttr }
      className={wrapperClassName}
      href={linkUrl}
      target={linkTarget}
    >
      <OptimizedImage alt={`Logo ${text}`} src={imageUrl} />
      <div className="seal-text">
        <b>{text}</b>
        <span>{helperText}</span>
      </div>
    </WrapperElement>
  );
};

export default Seal;
