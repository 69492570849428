import React, { useCallback } from 'react';

import classNames from 'classnames';

import HelpLink from './HelpLink';

const SideBar = (props) => {
  const { history, categories } = props;

  const onClickLink = useCallback((path, event) => {
    event?.preventDefault();
    if (path) {
      history.push({ pathname: path });
    }
  }, [history]);

  return (
    <div className="sidebar">
      <div className="sidebar__menu">
        <div className="sidebar__parent-title">
          <span>Search assistant</span>
        </div>
        <ul className="siblings-list">
          {categories
            .filter(({ isHidden }) => !isHidden)
            .map(({ nodeKey, title }) => (
              <HelpLink key={`sidemenu-${nodeKey}`} nodeKey={nodeKey} redirectToPath={onClickLink}>
                <li
                  className={classNames({
                    'siblings-list-item': true,
                    active: false,
                  })}
                >
                  <div className="siblings-list-item__wrapper">{title}</div>
                  <i className="icon-arrow-right" />
                </li>
              </HelpLink>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
