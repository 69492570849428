import queryString from 'query-string';

export const parseError = (error) => {
  try {
    const json = JSON.parse(error.responseText);

    for (const key in json) {
      const value = json[key];
      return `${Array.isArray(value) ? value[0] : value }: '${key}'`;
    }
  } catch (e) {
    return error.statusText;
  }
};

export const getParameterByName = (name, url = location?.href) => {
  const urlParsed = queryString.parseUrl(url, {
    parseBooleans: true,
    parseNumbers: true,
  });
  const { query } = urlParsed;
  let value;

  if (name in query) {
    value = query[name];
  }

  return value;
};

export const isSameString = (str1, str2) => {
  let sameString = false;
  if (str1 && str2) {
    const aux1 = str1.split(' ').join('').toLowerCase();
    const aux2 = str2.split(' ').join('').toLowerCase();
    if (aux1 === aux2) {
      sameString = true;
    }
  }
  return sameString;
};
