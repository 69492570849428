import React from 'react';

import { HelpPage as Help } from '@ergeon/help-components';

// eslint-disable-next-line import/no-named-as-default
import authService from 'common/auth';
import { defaultCategories } from 'data/help-categories';

const HELP_DOMAIN_ID = '2';

const HelpPage = (props) => {

  return (
    <Help
      apiHost={process.env.API_HOST}
      auth={authService}
      categories={defaultCategories}
      domain={HELP_DOMAIN_ID}
      level={process.env.NODE_ENV}
      {...props} />
  );
};

export default HelpPage;
