import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';
import { Portal } from 'react-portal';

import Button from '../../../atoms/Button'
import Notification from '../../Notification';
import ImageInfo from './ImageInfo';

import '../index.scss';

export interface WebShareFallbackInterface extends HTMLAttributes<HTMLDivElement>{
  url: string;
  title: string;
  successMessage?: string
  buttonText?: string
  isSuccessfulCopy: boolean;
  isOpenFallback: boolean;
  onClose: () => void;
  onCopyUrl: () => void;
}

const DEFAULT_SUCCESS_MESSAGE = 'Copied successfully!'
const DEFAULT_BUTTON_TEXT  = 'Copy'

/**
 * Fallback for web share button when WebShare API is not supported
 * @param props {WebShareFallbackInterface}
 */
const WebShareFallback = (props: WebShareFallbackInterface) => {
  const {
    url,
    title,
    isSuccessfulCopy,
    buttonText = DEFAULT_BUTTON_TEXT,
    successMessage = DEFAULT_SUCCESS_MESSAGE,
    isOpenFallback,
    onClose,
    onCopyUrl,
    ...divAttr
  } = props;

  return (
    <>
      <Portal>
        <div className={classNames('ShareSlide-NotificationWrapper', {
          'is-Successful': isSuccessfulCopy
        })}>
          <Notification>
           {successMessage}
          </Notification>
        </div>
        <div { ...divAttr } className={classNames('ShareSlide', {
          'is-Open': isOpenFallback, // ShareSlide fallback is closed by default
        })}>
          <ImageInfo title={title} url={url} onClose={onClose} />
          <Button
            className="ShareSlide-button"
            flavor="regular"
            size="medium"
            taste="line"
            onClick={onCopyUrl}>
            {buttonText}
          </Button>
        </div>
      </Portal>
    </>
  )
};

export default WebShareFallback;
