import React, { useCallback, useMemo } from 'react';

import { MetaTags, Spinner } from '@ergeon/core-components';

import { getParameterByName } from 'utils/utils';

import RenderContent from './RenderContent';
import SideBar from './SideBar';

import './index.scss';

const HelpPage = (props) => {
  const { question, answer, categories, history, onSearch } = props;

  const baseMeta = useMemo(() => {
    return {
      title: 'Help & Customer Service | Ergeon.com',
      description:
        'Our mission is to simplify home improvement by empowering skilled local contractors, ' +
        'contact us to learn how we can help you with your new fence project.',
    };
  }, []);

  const getMeta = useCallback(() => {
    const query = getParameterByName('q');
    if (query) {
      return {
        ...baseMeta,
        title: `Search ”${query}” | ${baseMeta.title}`,
      };
    }
    return baseMeta;
  }, [baseMeta]);

  const showSpinner = useMemo(() => !answer, [answer]);

  const searchRouting = useCallback((value) => {
    onSearch(value);
  }, [onSearch]);

  return (
    <>
      <MetaTags getMeta={getMeta} />
      <div className="help-page">
        <div className="help-page__body wrapper-1180">
          <div className="help-page__content">
            <SideBar categories={categories} history={history} />
            {showSpinner ? (
              <Spinner active borderWidth={0.16} color="blue" size={64} />
            ) : (
              <RenderContent question={question} answer={answer} onSearch={searchRouting} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpPage;
