import React, { HTMLAttributes, ReactNode, useMemo } from 'react';

import classNames from 'classnames';

import './index.scss';
import '../ToolButton/index.scss';

type ToolbarProps = {
  children: ReactNode;
  className: string;
  orientation: 'vertical' | 'horizontal';
} & HTMLAttributes<HTMLDivElement>;

/**
 * This components represents UI of toolbar.
 * Should be using with ToolButtons and ToolbarGroups inside.
 * Can be horizontal or vertical.
 */
const Toolbar = (props: ToolbarProps) => {
  const { children, className, orientation = 'vertical', ...divAttr } = props;

  const wrapperClasses = useMemo(() => classNames({
    'toolbar-wrapper': true,
    [orientation]: true,
    [className]: className,
  }), [className]);

  return <div { ...divAttr} className={wrapperClasses}>{children}</div>;
};

export default Toolbar;
