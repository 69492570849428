import Heart from 'assets/favicon.svg';
import Growth from 'assets/growth.svg';
import Sales from 'assets/sales.svg';
import Ops from 'assets/ops.svg';
import Engineering from 'assets/engineering.svg';
import Finance from 'assets/finance.svg';
import Hr from 'assets/hr.svg';
import Category from 'assets/category.svg';
import Materials from 'assets/materials.svg';
import CustomerService from 'assets/customer-service.svg';

export const defaultCategories = [{
  icon: Heart,
  nodeKey: '202000389',
  title: 'Company Information',
}, {
  icon: Growth,
  nodeKey: '202000391',
  title: 'Growth',
}, {
  icon: Sales,
  nodeKey: '202000392',
  title: 'Sales',
}, {
  icon: Ops,
  nodeKey: '202000393',
  title: 'Operations',
}, {
  icon: Engineering,
  nodeKey: '202000394',
  title: 'Engineering',
}, {
  icon: Finance,
  nodeKey: '202000396',
  title: 'Finance',
}, {
  icon: Hr,
  nodeKey: '202000397',
  title: 'Human Resources',
}, {
  icon: Category,
  nodeKey: '202102093',
  title: 'Category',
}, {
  icon: Materials,
  nodeKey: '202102094',
  title: 'Materials',
}, {
  icon: CustomerService,
  nodeKey: '202102255',
  title: 'Customer Service',
}];
