import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import parse from 'html-react-parser';
import { SearchInputToggle, FeedbackForm, MetaTags, Notification, Spinner } from '@ergeon/core-components';
import { uploadFile } from '@ergeon/file-storage';

import HelpSearchResults from 'components/HelpSearchResults';
import { getParameterByName, isSameString } from 'utils/utils';
import { getHelpNode, getGlobalHelpResults, getLocalHelpResults, submitFeedback } from 'api/help';
import { parseAPIError } from 'utils/api';
import { PRIVATE_DOMAIN_ID, DEFAULT_DATE_FORMAT } from 'utils/constants';
import HomeIcon from 'assets/icon-home-black.svg';

import NotFoundPage from '../NotFoundPage';

import { replaceNodeWithCustomComponent, removeExtension } from './utils';
import HelpLink from './HelpLink';
import './index.scss';

const MAX_FEEDBACK_UPLOADS = 4;
const DEFAULT_TOGGLE_MSG = 'Activate local search under current node (or current page)';

class HelpPage extends React.Component {

  static propTypes = {
    apiHost: PropTypes.string.isRequired,
    auth: PropTypes.shape({
      getAuthHeader: PropTypes.func,
    }),
    categories: PropTypes.arrayOf(PropTypes.shape({
      icon: PropTypes.node,
      nodeKey: PropTypes.string,
      title: PropTypes.string,
    })).isRequired,
    domain: PropTypes.string,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        nodeKey: PropTypes.string,
      }),
    }).isRequired,
    level: PropTypes.string.isRequired,
    isRootPath: PropTypes.bool,
  };

  state = {
    loading: true,
    helpNode: {
      urn: null,
      breadcrumb: [],
      parent: {
        urn: null,
        title: null,
        children: this.props.categories,
      },
    },
    error: null,
    search: '',
    searchResults: null,
    expandedSidebar: false,
    nodeMeta: null,
    isFeedbackLoading: false,
    isFeedbackSuccess: false,
    feedbackSubmitError: null,
    feedbackValue: '',
    isLocalSearch: false,
  };

  // eslint-disable-next-line complexity
  componentDidMount() {
    const nodeKey = this.props.match.params.nodeKey;
    const isRootPath = this.props.isRootPath;
    const query = getParameterByName('q');
    const nodeKeyParam = getParameterByName('node_key');
    const localNode = getParameterByName('local');
    const shouldRedirect = (nodeKeyParam && nodeKeyParam !== nodeKey);

    if (shouldRedirect) {
      const { history } = this.props;
      const pathname = isRootPath ? nodeKeyParam : `/help/${nodeKeyParam}`;
      history.push({
        pathname,
      });
    }

    if (nodeKey && !query) {
      this.retrieveHelpNode(nodeKey);
    }

    if (query) {
      this.onSearch(query, localNode);
    }
  }

  // eslint-disable-next-line complexity
  async componentDidUpdate(prevProps) {
    const nodeKey = this.props.match.params.nodeKey;
    const search = this.props.location.search;
    const prevNodeKey = prevProps.match.params.nodeKey;
    const prevSearch = prevProps.location.search;
    const query = getParameterByName('q');
    const localNode = getParameterByName('local');

    if (nodeKey !== prevNodeKey && nodeKey && !query) {
      await this.retrieveHelpNode(nodeKey);
    }

    if (query && prevSearch !== search) {
      this.onSearch(query, localNode);
    }
  }

  get baseMeta() {
    const { domain } = this.props;
    const isPrivate = (domain === PRIVATE_DOMAIN_ID);

    return isPrivate ? {
      title: 'Company Wiki',
      description: '',
    } : {
      title: 'Help & Customer Service | Ergeon.com',
      description: 'Our mission is to simplify home improvement by empowering skilled local contractors, ' +
        'contact us to learn how we can help you with your new fence project.',
    };
  }

  getMeta() {
    const { error, nodeMeta, search } = this.state;
    const query = getParameterByName('q');
    if (query) {
      return {
        ...this.baseMeta,
        title: `Search ”${search}” | ${this.baseMeta.title}`,
      };
    }
    if (error) {
      return this.baseMeta;
    }
    return nodeMeta || this.baseMeta;
  }

  async retrieveHelpNode(nodeUrn) {
    const { domain, apiHost, auth } = this.props;
    const { helpNode } = this.state;

    if (helpNode.nodeKey === nodeUrn) {
      return helpNode; // already retrieved
    }

    this.setState({
      error: null,
      loading: true,
      search: '',
      searchResults: null,
    });

    try {
      const helpNodeConfig = {
        apiHost,
        auth,
        domain,
        nodeKey: nodeUrn,
      };
      const data = await getHelpNode(helpNodeConfig);
      if (data.redirect) {
        window.location.href = data.redirectUrl;
      }
      const nodeMeta = this.getNodeMetaTags(data);
      this.setState({ loading: false, helpNode: data, nodeMeta, error: null });
    } catch (apiError) {
      // eslint-disable-next-line no-console
      console.warn(apiError);
      this.setState({
        loading: false,
        error: parseAPIError(apiError),
      });
    }
  }

  async getNodeMetaTags(helpNode) {
    return {
      ...this.baseMeta,
      title: `${helpNode.title} | ${this.baseMeta.title}`,
      description: helpNode.short_memo || '',
    };
  }

  resetFeedbackState() {
    this.setState({
      isFeedbackLoading: false,
      isFeedbackSuccess: false,
      feedbackSubmitError: null,
    });
  }

  searchRedirect(value, isLocal) {
    const { nodeKey } = this.state.helpNode;
    const isRootPath = this.props.isRootPath;
    if (value) {
      const pathname = isRootPath ? '/search' : '/help/search';
      // use local param when state doesnt have helpNode, usually after a re-render
      const nodeKeyId = nodeKey || getParameterByName('local');
      this.props.history.push({
        pathname,
        search: `?q=${value}${isLocal ? `&local=${nodeKeyId}` : ''}`,
      });
    }
  }

  redirectToPath(path, event) {
    const { history } = this.props;

    if (event) {
      event.preventDefault();
    }

    if (path) {
      this.resetFeedbackState();
      history.push({
        pathname: path,
      });
    }
  }

  onSearch(value, localNode) {
    const { domain, apiHost, auth, categories } = this.props;
    const defaultHelpNode = {
      urn: null,
      breadcrumb: [],
      parent: {
        urn: null,
        title: null,
        children: categories,
      },
    };
    const helpNodeConfig = {
      apiHost,
      auth,
      domain,
      nodeKey: value,
    };

    this.setState({
      error: null,
      loadingSearch: true,
      search: value,
    });

    if (!localNode) {
      getGlobalHelpResults(helpNodeConfig)
        .then(results =>
          this.setState({
            error: null,
            helpNode: defaultHelpNode,
            loadingSearch: false,
            searchResults: results.data,
          }),
        );
      return;
    }
    const localHelpNodeConfig = {
      apiHost,
      auth,
      domain,
      nodeKey: localNode,
      searchTerm: value,
    };
    getLocalHelpResults(localHelpNodeConfig)
      .then(results =>
        this.setState({
          error: null,
          helpNode: defaultHelpNode,
          loadingSearch: false,
          searchResults: results.data,
        }),
      );
  }

  onChangeFeedback(event) {
    this.setState({
      feedbackValue: event.target.value,
    });
  }

  async onUploadImages(imagesList) {
    const { level } = this.props;
    const results = await Promise.allSettled(imagesList.map((image) => {
      const file = image.file;
      const fileName = removeExtension(image.file.name);
      return uploadFile(file, fileName, level);
    }));
    return results;
  }

  async onSubmitFeedback({ images, resetImages }) {
    const { domain, apiHost, auth } = this.props;
    const { feedbackValue } = this.state;
    const { id: nodeId } = this.state.helpNode;
    const helpNodeConfig = {
      apiHost,
      auth,
      domain,
      nodeId,
      content: feedbackValue,
    };
    this.setState({ isFeedbackLoading: true });
    // upload images if any and append them to the content
    if (images.length) {
      // lets block till we have all the images uploaded, so we can append them to the content
      const results = await this.onUploadImages(images);
      const imagesUrls = results.map(result => {
        if (result.status === 'fulfilled') {
          return result.value;
        }
        return null;
      }).filter(url => url);
      helpNodeConfig.content += `\n${imagesUrls.join('\n')}`;
    }
    submitFeedback(helpNodeConfig)
      .then(
        this.setState({
          isFeedbackSuccess: true,
        }),
      ).catch((apiError) => {
        console.warn(apiError);
        this.setState({
          feedbackSubmitError: parseAPIError(apiError),
        });
      })
      .finally(() => {
        this.setState({
          isFeedbackLoading: false,
          feedbackValue: '',
        });
        resetImages();
      });
  }

  onSearchSubmit(event) {
    event.preventDefault();
    const { search, isLocalSearch: isLocal } = this.state;
    this.searchRedirect(search, isLocal);
    this.setState({ isLocalSearch: false });
  }

  onToggleClick(toggleSearchValue) {
    const { nodeKey } = this.state.helpNode;
    const key = nodeKey || getParameterByName('local');
    if (!key) return;
    this.setState({ isLocalSearch: toggleSearchValue });
  }

  onSearchChange(value) {
    this.setState({ search: value });
  }

  renderError() {
    const { error } = this.state;
    const isNotFound = error.statusCode === 404;
    let errorMessage = error.response;
    if (error.data) {
      errorMessage = Object.values(error.data).join('\n');
    }
    return (
      <>
        {isNotFound ?
          <NotFoundPage /> :
          <Notification
            mode="embed"
            type="Error">
            There was an error trying to retrieve help page.<br />
            {errorMessage}
          </Notification>
        }
      </>
    );
  }

  renderFeedbackSubmitError() {
    const { feedbackSubmitError } = this.state;
    let errorMessage = feedbackSubmitError.response;
    if (feedbackSubmitError.data) {
      errorMessage = Object.values(feedbackSubmitError.data).join('\n');
    }
    return (
      <Notification
        mode="embed"
        type="Error">
        There was an error trying to submit your feedback, please try again later.<br />
        {errorMessage}
      </Notification>
    );
  }

  renderLoading(loading, isPageLoading = true) {
    const classes = classNames({
      'loading': isPageLoading,
      'loading--hidden': !loading,
      'loading-content': !isPageLoading,
    });

    return (
      <div className={classes}>
        <Spinner active borderWidth={0.16} color="blue" size={64} />
      </div>
    );
  }

  renderSearchComponent() {
    const { loadingSearch, search, isLocalSearch } = this.state;
    return (
      <SearchInputToggle
        placeholder="Search..."
        size="small"
        isLoading={loadingSearch}
        isToggleEnabled={isLocalSearch}
        tooltipMsg={DEFAULT_TOGGLE_MSG}
        onSubmit={this.onSearchSubmit.bind(this)}
        onChange={this.onSearchChange.bind(this)}
        onToggleClick={this.onToggleClick.bind(this)}
        value={search}
      />
    );
  }

  renderSearchResults() {
    const { search, searchResults, loadingSearch, isLocalSearch } = this.state;
    const { nodeKey } = this.state.helpNode;
    const { history, isRootPath } = this.props;
    const key = nodeKey || getParameterByName('local');
    const searchResultsNode = searchResults ?
      <HelpSearchResults
        history={history}
        results={searchResults}
        search={search}
        isRootPath={isRootPath}
      /> : null;
    const tooltipMsg = key ? DEFAULT_TOGGLE_MSG : 'You need to be under a node in order to use local search';
    return (
      <div className="help-page__search-results">
        <h1 className="h3 spacing after__is-12">Help Search Results</h1>
        <SearchInputToggle
          placeholder="Search..."
          size="small"
          isLoading={loadingSearch}
          showToggle={isLocalSearch}
          isToggleEnabled={isLocalSearch}
          isFullWidth
          tooltipMsg={tooltipMsg}
          onSubmit={this.onSearchSubmit.bind(this)}
          onChange={this.onSearchChange.bind(this)}
          onToggleClick={this.onToggleClick.bind(this)}
          value={search}
        />
        <div className="help-page__search-results-wrapper">
          {searchResultsNode}
          {this.renderLoading(loadingSearch)}
        </div>
      </div>
    );
  }

  renderMetadataNode() {
    const { helpNode } = this.state;
    const { domain } = this.props;
    const {
      id,
      modifiedAt,
      modifiedByName,
      ownerName,
    } = helpNode;
    const isPrivate = (domain === PRIVATE_DOMAIN_ID);
    // URL to edit node with specific id in public_admin
    const editUrl = `${this.props.apiHost}/public-admin/help/node/${id}/change/`;
    const modifiedDate = moment(modifiedAt).format(DEFAULT_DATE_FORMAT);
    let lastModified = `Last modified: ${modifiedDate}`;
    if (isPrivate) {
      lastModified += ` by ${modifiedByName}`;
    }
    return (
      <div className="help-page__metadata">
        {isPrivate && (
          <div className="help-page__metadata-header">
            {ownerName && <span>{`Owner: ${ownerName}`}</span>}
            <span><a href={editUrl}>Edit</a></span>
          </div>
        )}
        <div className="help-page__metadata-footer">
          {lastModified}
        </div>
      </div>
    );
  }

  // eslint-disable-next-line complexity
  renderNodeContent() {
    const { domain, isRootPath } = this.props;
    const {
      error,
      loading,
      loadingSearch,
      isFeedbackLoading,
      isFeedbackSuccess,
      feedbackSubmitError,
      feedbackValue,
    } = this.state;
    const {
      breadcrumb,
      title,
      shortMemo,
      content,
    } = this.state.helpNode;

    if (loading || loadingSearch) {
      return this.renderLoading(true, false);
    }

    const showMetadata = (!loadingSearch && !loading && !error);

    const options = {
      replace: node => replaceNodeWithCustomComponent(title, node, isRootPath),
    };

    const isPrivate = (domain === PRIVATE_DOMAIN_ID);

    return (
      <div className="help-page__node">
        <div className="help-breadcrumb">
          <span className="help-breadcrumb__item">
            <HelpLink
              className="help-breadcrumb__link"
              redirectToPath={this.redirectToPath.bind(this)}
              isRootPath={isRootPath}
            >
              <img src={HomeIcon} />
            </HelpLink>
          </span>
          {[...breadcrumb].reverse().slice(1).map(
            ({ title, nodeKey }) => (
              <span className="help-breadcrumb__item" key={`header-breadcrumb-${nodeKey}`}>
                <HelpLink
                  className="help-breadcrumb__link"
                  nodeKey={nodeKey}
                  redirectToPath={this.redirectToPath.bind(this)}
                  isRootPath={isRootPath}
                >
                  {title}
                </HelpLink>
              </span>
            ),
          )}
        </div>
        <h1 className="h3 help-page__title">{title}</h1>
        {shortMemo && !isSameString(title, shortMemo) && (
          <div className="help-page__subtitle">
            {shortMemo}
          </div>
        )}
        <div className="help-page__node-content">
          {error && this.renderError()}
          {(!error && content) && parse(content, options)}
        </div>
        {isPrivate &&
          <div className="help-page__feedback spacing before__is-30">
            <h5 className="spacing after__is-6">Feedback</h5>
            {feedbackSubmitError && this.renderFeedbackSubmitError()}
            {!feedbackSubmitError &&
              <FeedbackForm
                // eslint-disable-next-line max-len
                placeholder="Please submit any corrections or necessary updates for the page here. They will be automatically posted to #product-bugs so that they can be fixed."
                isLoading={isFeedbackLoading}
                onSubmit={this.onSubmitFeedback.bind(this)}
                onChange={this.onChangeFeedback.bind(this)}
                isSuccess={isFeedbackSuccess}
                successMsg="Thanks! We have got your feedback"
                maxUploads={MAX_FEEDBACK_UPLOADS}
                value={feedbackValue} />
            }
          </div>
        }
        {showMetadata && this.renderMetadataNode()}
      </div>
    );
  }

  // eslint-disable-next-line complexity
  renderSidebar() {
    const { isRootPath } = this.props;
    const {
      helpNode: {
        nodeKey: currentNodeKey,
        parent,
      },
      expandedSidebar,
    } = this.state;

    const parentChildren = parent && parent.children || [];
    let parentTitle = parent && parent.title || 'All topics';
    let parentNodeKey = parent && parent.nodeKey || '';

    if (parentTitle === 'Home') {
      parentTitle = 'All topics';
      parentNodeKey = '';
    }

    const classes = {
      'sidebar': true,
      'sidebar--expanded': expandedSidebar,
    };

    return (
      <div className={classNames(classes)}>
        <div className="sidebar__menu">
          <div className="sidebar__parent-title">
            <HelpLink
              className="icon-arrow-left"
              nodeKey={parentNodeKey}
              redirectToPath={this.redirectToPath.bind(this)}
              isRootPath={isRootPath}
            />
            {parentTitle}
            <i className="icon-arrow-down" onClick={() => this.setState({ expandedSidebar: !expandedSidebar })} />
          </div>
          <ul className="siblings-list">
            {parentChildren.filter(({ isHidden }) => !isHidden).map(
              ({ nodeKey, title }) => (
                <HelpLink
                  key={`sidemenu-${nodeKey}`}
                  nodeKey={nodeKey}
                  redirectToPath={this.redirectToPath.bind(this)}
                  isRootPath={isRootPath}
                >
                  <li
                    className={classNames({
                      'siblings-list-item': true,
                      'active': currentNodeKey === nodeKey,
                    })}>
                    <div className="siblings-list-item__wrapper">
                      {title}
                    </div>
                    <i className="icon-arrow-right" />
                  </li>
                </HelpLink>
              ),
            )}
          </ul>
        </div>
      </div>
    );
  }
  // eslint-disable-next-line complexity
  render() {
    const { helpNode, searchResults, loadingSearch } = this.state;
    const showContent = (helpNode && !searchResults && !loadingSearch);
    const contentNode = (showContent) ? this.renderNodeContent() : null;
    const sidebarNode = helpNode ? this.renderSidebar() : null;
    const searchNode = (!searchResults && !loadingSearch) ? (
      this.renderSearchComponent()
    ) : null;
    const searchResultsNode = (searchResults || loadingSearch) ? this.renderSearchResults() : null;

    return (
      <>
        <MetaTags getMeta={this.getMeta.bind(this)} />
        <div className="help-page">
          <div className="shadow-block">
            <div className="help-page__header wrapper-1180">
              <div className="h2">Help & Customer Service</div>
              <div className="help-page__header-search">
                {searchNode}
              </div>
            </div>
          </div>
          <div className="help-page__body wrapper-1180">
            <div className="help-page__content">
              {sidebarNode}
              {contentNode}
              {searchResultsNode}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HelpPage;
