(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("prop-types"), require("query-string"), require("@ergeon/core-components"), require("classnames"), require("moment"), require("html-react-parser"), require("@ergeon/file-storage"), require("axios"), require("lodash-humps"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "prop-types", "query-string", "@ergeon/core-components", "classnames", "moment", "html-react-parser", "@ergeon/file-storage", "axios", "lodash-humps"], factory);
	else if(typeof exports === 'object')
		exports["@ergeon/help-components"] = factory(require("react"), require("prop-types"), require("query-string"), require("@ergeon/core-components"), require("classnames"), require("moment"), require("html-react-parser"), require("@ergeon/file-storage"), require("axios"), require("lodash-humps"));
	else
		root["@ergeon/help-components"] = factory(root["react"], root["prop-types"], root["query-string"], root["@ergeon/core-components"], root["classnames"], root["moment"], root["html-react-parser"], root["@ergeon/file-storage"], root["axios"], root["lodash-humps"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__8156__, __WEBPACK_EXTERNAL_MODULE__5099__, __WEBPACK_EXTERNAL_MODULE__7048__, __WEBPACK_EXTERNAL_MODULE__7959__, __WEBPACK_EXTERNAL_MODULE__8310__, __WEBPACK_EXTERNAL_MODULE__3192__, __WEBPACK_EXTERNAL_MODULE__9998__, __WEBPACK_EXTERNAL_MODULE__1014__, __WEBPACK_EXTERNAL_MODULE__3300__, __WEBPACK_EXTERNAL_MODULE__4171__) => {
return 