import React from 'react';

import { ImageGallery } from '@ergeon/core-components';

export const getWidthHeightFromStyle = (style, property) => {
  const rawProp = String.raw`${property}`;
  const regex = new RegExp(`${rawProp}: (\\d+)%`);
  const propertyMatch = style.match(regex);
  if (propertyMatch) {
    return parseInt(propertyMatch[1], 10);
  }
  return null;
};

// eslint-disable-next-line complexity
export const replaceNodeWithCustomComponent = (title, node, isRootPath = false) => {

  if (
    node.type === 'tag' &&
    node.name === 'img' &&
    node.attribs['data-stringify-type'] !== 'emoji'
  ) {
    return replaceWithImageGallery(node, title);
  }

  if (node.type === 'tag') {
    return replaceHelpLinkInNode(node, isRootPath);
  }

};

// eslint-disable-next-line complexity
const replaceWithImageGallery = (node, title) => {
  const images = [{
    title,
    url: node.attribs.src,
  }];

  let width = '100%';
  let height = '100%';
  if (node.attribs.width && node.attribs.height) {
    width = parseInt(node.attribs.width, 10);
    height = parseInt(node.attribs.height, 10);
  } else if (node.attribs.style) {
    // if we have img style, we can get width and height to properly size the image
    // summernote allows to use 100%, 50% and 25% and includes this as style
    const style = node.attribs.style;
    const widthMatch = getWidthHeightFromStyle(style, 'width');
    const heightMatch = getWidthHeightFromStyle(style, 'height');

    width = widthMatch ? `${widthMatch}%` : width;
    height = heightMatch ? `${heightMatch}%` : height;
  }

  return (
    <ImageGallery
      height={height}
      images={images}
      initialSlide={0}
      isMaxHeight
      isShowNavigation={false}
      noMaxHeight
      width={width} />
  );

};

const replaceHelpLinkInNode = (node, isRootPath) => {

  const updatedNode = { ...node };

  updatedNode.children = node?.children?.map((child) => {
    const isTagA = child.name === 'a';

    if (isTagA) {
      return replaceHelpLink(child, isRootPath);
    }
    return handleChildrenNode(child);
  });

  return updatedNode;
};

const replaceHelpLink = (child, isRootPath) => {
  // match node pattern regex eg: ./18278
  const regex = /^\.\/[0-9].*$/i;
  const nodePattern = child.attribs?.href;
  const shouldReplaceLink = regex.test(nodePattern);

  if (shouldReplaceLink) {
    const nodeKey = child.attribs?.href?.replace('./', '');
    const baseHelpPath = isRootPath ? '' : '/help';
    const newHref = `${baseHelpPath}/${nodeKey}`;
    child.attribs.href = newHref;
  }
  return handleChildrenNode(child);
};

const handleChildrenNode = (child) => {
  if (child.children?.length) {
    return replaceHelpLinkInNode(child);
  }
  return child;
};

export const removeExtension = (filename) => {
  return filename.substring(0, filename.lastIndexOf('.')) || filename;
};
