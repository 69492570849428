import React, { forwardRef, HTMLAttributes } from 'react';
import classNames from 'classnames';

import { ButtonProps as BaseButtonProps } from './types';

import './Button.scss';

export type ButtonProps = BaseButtonProps & HTMLAttributes<HTMLButtonElement & HTMLAnchorElement>;

const Button = forwardRef<HTMLElement, ButtonProps>((props, ref) => {
  const {
    ariaLabel,
    asAnchor = false,
    className = '',
    disabled = false,
    flavor = 'primary',
    size = 'medium',
    taste = 'solid',
    children,
    href,
    onClick,
    target,
    isFullWidth,
    ...elementProps
  } = props;

  const classes = classNames({
    'button': true,
    'button--primary': flavor === 'primary',
    'button--cta': flavor === 'cta' || flavor === 'secondary',
    'button--regular': flavor === 'regular',
    'button--attention': flavor === 'attention',
    'button--action': flavor === 'action',

    'button--size__small': size === 'small',
    'button--size__medium': size === 'medium',
    'button--size__large': size === 'large',

    'button--full-width': !!isFullWidth,

    'taste__solid': taste === 'solid',
    'taste__line': taste === 'line',
    'taste__boundless': taste === 'boundless',
    disabled,
    [className]: className,
  });

  const Node = asAnchor ? 'a' : 'button';
  const NodeAttr = elementProps as HTMLAttributes<Node>

  return (
    <Node
      {...NodeAttr}
      aria-label={ariaLabel}
      className={classes}
      disabled={disabled}
      href={href}
      onClick={onClick}
      ref={ref as unknown as typeof Node}
      target={target}>
      {children}
    </Node>
  );
});

export default Button;
