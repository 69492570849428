import React from 'react';

import ReactDOM from 'react-dom';
import { NavLink, Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
// eslint-disable-next-line import/no-unresolved
import { NavLinkContext, UpcomingFeaturesHandler } from '@ergeon/core-components';

import HelpPage from 'components/HelpPage';
import HomePage from 'components/HomePage';
import LandingPage from 'components/LandingPage';
import Layout from 'components/Layout';
import NotFoundPage from 'components/NotFoundPage';
import store from 'flux/store';

import './index.scss';
// eslint-disable-next-line import/no-unresolved
import '@ergeon/core-components/dist/index.css';

const history = createBrowserHistory();

const App = (
  <Provider store={store}>
    <Router history={history}>
      <UpcomingFeaturesHandler>
        <NavLinkContext.Provider value={NavLink}>
          <Layout>
            <Switch>
              <Route component={HomePage} exact path="/" />
              <Route component={LandingPage} exact path="/help" />
              <Route component={HelpPage} exact path="/help/:nodeKey" />
              <Route component={HelpPage} exact path="/help/search" />
              <Route component={NotFoundPage} exact path="*" />
            </Switch>
          </Layout>
        </NavLinkContext.Provider>
      </UpcomingFeaturesHandler>
    </Router>
  </Provider>
);

ReactDOM.render(App, document.getElementById('root'));
