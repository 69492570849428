import React, { HTMLAttributes, useCallback } from 'react';

import classNames from 'classnames';
import { ListItem } from './types';
import { DropdownListItem } from './ListItem';
import { DropdownItemTypeEnum } from './contants';

import './index.scss';

type DropdownListProps = {
  list: ListItem[]
} & HTMLAttributes<HTMLUListElement>;

/**
 * This a dropdown list component. We use it for rendering a list of actions with icons or without
 * In general this component should be used as a dropdown.
 */
const DropdownList = (props: DropdownListProps) => {
  const { list, className, ...ulAttr } = props;

  const handleClick = useCallback((item: ListItem) => {
    if (!item.disabled) {
      item.onClick();
    }
  }, []);

  const isDivider = useCallback((item: ListItem): boolean => item.type === DropdownItemTypeEnum.Divider, []);

  return (
    <ul {...ulAttr} className={classNames('dropdown-list', className)}>
      {list.map((item, index) => (
        <li
          className={classNames('dropdown-list--row', 
            {disabled: item.disabled}, 
            {divider: isDivider(item)})}
          key={`${item.label}${index}`}
          onClick={() => isDivider(item) ? undefined : handleClick(item)}
        >
          <DropdownListItem item={item} />
        </li>
      ))}
    </ul>);
};

export default DropdownList;
