/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import HelpSearchField from '../HelpSearchFieldNew';

const RenderContent = (props) => {
  const { question, answer, onSearch } = props;

  return (
    <div className="RenderContent">
      <div className="RenderContent-wrapper">
        <div className="question">
          <p className="title">Your question</p>
          <p className="content">{question}</p>
        </div>
        <div className="answer">
          <p className="title">Answer</p>
          <p className="content">{answer}</p>
        </div>
        <div className="research">
          <p className="title">Ask follow up</p>
          <div className="spacing before__is-24 search-field-container">
            <HelpSearchField onSubmit={onSearch} />
          </div>
        </div>
        <div className="line"></div>
        <div className="contact title">
            Feel free to <a href="tel:+18883743661">&nbsp;call us&nbsp;</a> if you don't find your answer here
        </div>
      </div>
    </div>
  );
};

export default RenderContent;
