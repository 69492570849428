(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@ergeon/erg-utils-js"), require("@googlemaps/js-api-loader"), require("@react-spring/web"), require("classnames"), require("lodash"), require("lodash/find"), require("lodash/first"), require("lodash/includes"), require("lodash/isEmpty"), require("lodash/isNil"), require("lodash/keys"), require("lodash/last"), require("lodash/noop"), require("lodash/omit"), require("lodash/pick"), require("lodash/sortBy"), require("lodash/throttle"), require("lodash/times"), require("lodash/toPairs"), require("lodash/union"), require("lodash/uniqueId"), require("lodash/zipObject"), require("lodash/zipWith"), require("moment"), require("prop-types"), require("react"), require("react-datepicker"), require("react-flickity-component"), require("react-helmet"), require("react-image-gallery"), require("react-images-uploading"), require("react-input-mask-format"), require("react-measure"), require("react-portal"), require("react-pose"), require("react-router-dom"), require("react-select"), require("react-show-more-text"), require("react-signature-canvas"), require("react-svg"), require("react-transition-group"), require("requestanimationframe-timer"), require("ssr-window"), require("swiper/core"), require("swiper/react"), require("uuid"));
	else if(typeof define === 'function' && define.amd)
		define("@ergeon/core-components", ["@ergeon/erg-utils-js", "@googlemaps/js-api-loader", "@react-spring/web", "classnames", "lodash", "lodash/find", "lodash/first", "lodash/includes", "lodash/isEmpty", "lodash/isNil", "lodash/keys", "lodash/last", "lodash/noop", "lodash/omit", "lodash/pick", "lodash/sortBy", "lodash/throttle", "lodash/times", "lodash/toPairs", "lodash/union", "lodash/uniqueId", "lodash/zipObject", "lodash/zipWith", "moment", "prop-types", "react", "react-datepicker", "react-flickity-component", "react-helmet", "react-image-gallery", "react-images-uploading", "react-input-mask-format", "react-measure", "react-portal", "react-pose", "react-router-dom", "react-select", "react-show-more-text", "react-signature-canvas", "react-svg", "react-transition-group", "requestanimationframe-timer", "ssr-window", "swiper/core", "swiper/react", "uuid"], factory);
	else if(typeof exports === 'object')
		exports["@ergeon/core-components"] = factory(require("@ergeon/erg-utils-js"), require("@googlemaps/js-api-loader"), require("@react-spring/web"), require("classnames"), require("lodash"), require("lodash/find"), require("lodash/first"), require("lodash/includes"), require("lodash/isEmpty"), require("lodash/isNil"), require("lodash/keys"), require("lodash/last"), require("lodash/noop"), require("lodash/omit"), require("lodash/pick"), require("lodash/sortBy"), require("lodash/throttle"), require("lodash/times"), require("lodash/toPairs"), require("lodash/union"), require("lodash/uniqueId"), require("lodash/zipObject"), require("lodash/zipWith"), require("moment"), require("prop-types"), require("react"), require("react-datepicker"), require("react-flickity-component"), require("react-helmet"), require("react-image-gallery"), require("react-images-uploading"), require("react-input-mask-format"), require("react-measure"), require("react-portal"), require("react-pose"), require("react-router-dom"), require("react-select"), require("react-show-more-text"), require("react-signature-canvas"), require("react-svg"), require("react-transition-group"), require("requestanimationframe-timer"), require("ssr-window"), require("swiper/core"), require("swiper/react"), require("uuid"));
	else
		root["@ergeon/core-components"] = factory(root["@ergeon/erg-utils-js"], root["@googlemaps/js-api-loader"], root["@react-spring/web"], root["classnames"], root["lodash"], root["lodash/find"], root["lodash/first"], root["lodash/includes"], root["lodash/isEmpty"], root["lodash/isNil"], root["lodash/keys"], root["lodash/last"], root["lodash/noop"], root["lodash/omit"], root["lodash/pick"], root["lodash/sortBy"], root["lodash/throttle"], root["lodash/times"], root["lodash/toPairs"], root["lodash/union"], root["lodash/uniqueId"], root["lodash/zipObject"], root["lodash/zipWith"], root["moment"], root["prop-types"], root["react"], root["react-datepicker"], root["react-flickity-component"], root["react-helmet"], root["react-image-gallery"], root["react-images-uploading"], root["react-input-mask-format"], root["react-measure"], root["react-portal"], root["react-pose"], root["react-router-dom"], root["react-select"], root["react-show-more-text"], root["react-signature-canvas"], root["react-svg"], root["react-transition-group"], root["requestanimationframe-timer"], root["ssr-window"], root["swiper/core"], root["swiper/react"], root["uuid"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__794__, __WEBPACK_EXTERNAL_MODULE__4975__, __WEBPACK_EXTERNAL_MODULE__2630__, __WEBPACK_EXTERNAL_MODULE__8310__, __WEBPACK_EXTERNAL_MODULE__467__, __WEBPACK_EXTERNAL_MODULE__2254__, __WEBPACK_EXTERNAL_MODULE__6842__, __WEBPACK_EXTERNAL_MODULE__2318__, __WEBPACK_EXTERNAL_MODULE__3974__, __WEBPACK_EXTERNAL_MODULE__1128__, __WEBPACK_EXTERNAL_MODULE__622__, __WEBPACK_EXTERNAL_MODULE__7303__, __WEBPACK_EXTERNAL_MODULE__1604__, __WEBPACK_EXTERNAL_MODULE__1443__, __WEBPACK_EXTERNAL_MODULE__6635__, __WEBPACK_EXTERNAL_MODULE__2432__, __WEBPACK_EXTERNAL_MODULE__4067__, __WEBPACK_EXTERNAL_MODULE__6432__, __WEBPACK_EXTERNAL_MODULE__1856__, __WEBPACK_EXTERNAL_MODULE__8303__, __WEBPACK_EXTERNAL_MODULE__3461__, __WEBPACK_EXTERNAL_MODULE__5474__, __WEBPACK_EXTERNAL_MODULE__4085__, __WEBPACK_EXTERNAL_MODULE__3192__, __WEBPACK_EXTERNAL_MODULE__5099__, __WEBPACK_EXTERNAL_MODULE__8156__, __WEBPACK_EXTERNAL_MODULE__4731__, __WEBPACK_EXTERNAL_MODULE__5212__, __WEBPACK_EXTERNAL_MODULE__5383__, __WEBPACK_EXTERNAL_MODULE__3077__, __WEBPACK_EXTERNAL_MODULE__7137__, __WEBPACK_EXTERNAL_MODULE__2344__, __WEBPACK_EXTERNAL_MODULE__8850__, __WEBPACK_EXTERNAL_MODULE__7689__, __WEBPACK_EXTERNAL_MODULE__5463__, __WEBPACK_EXTERNAL_MODULE__6128__, __WEBPACK_EXTERNAL_MODULE__1738__, __WEBPACK_EXTERNAL_MODULE__1338__, __WEBPACK_EXTERNAL_MODULE__8790__, __WEBPACK_EXTERNAL_MODULE__2067__, __WEBPACK_EXTERNAL_MODULE__5002__, __WEBPACK_EXTERNAL_MODULE__3602__, __WEBPACK_EXTERNAL_MODULE__8706__, __WEBPACK_EXTERNAL_MODULE__2909__, __WEBPACK_EXTERNAL_MODULE__9244__, __WEBPACK_EXTERNAL_MODULE__459__) {
return 