import React from 'react';

import { HelpLandingPage } from '@ergeon/help-components';

import { defaultCategories } from 'data/help-categories';

class LandingPage extends React.Component {
  render() {
    return (
      <HelpLandingPage
        isLargeIcons
        categories={defaultCategories}
        subtitle={`Here you can access the complete 
        documentation and playbooks for the 
        company and each business function`}
        title="Ergeon Wiki"
        {...this.props}/>
    );
  }
}

export default LandingPage;
