import React, { HTMLAttributes, useContext, useMemo } from 'react';
import classNames from 'classnames';

import { ButtonSize } from '../Button/types';
import { RadioGroupContext } from '../RadioGroup';

import './index.scss';

interface RadioButtonProps extends HTMLAttributes<HTMLLIElement>{
  value: unknown;
  children?: React.ReactNode;
  size?: Omit<ButtonSize, 'large'>;
}

const RadioButton = (props: RadioButtonProps) => {
  const { children, size = 'medium', value } = props;
  const { name, selectedValue, onChange, ...liAttr } = useContext(RadioGroupContext);

  const checked = useMemo(() => selectedValue === value, [selectedValue, value]);

  return (
    <div {...liAttr} className={classNames('radio-button', `is-${size}Size`)}>
      <label
        className={classNames('radio-button__label', { 'is-active': checked })}
        htmlFor={`radio-${value}`}>
          <input
            id={`radio-${value}`}
            name={name}
            value={String(value)}
            aria-checked={checked}
            type="radio"
            onChange={() => onChange?.(value)} />
          <div className="radio" />
          <div className="radio-button__node">{children}</div>
      </label>
    </div>
  );
};

export default RadioButton;
