import React from 'react';

import { ReactSVG } from 'react-svg';

import ErgeonLink from '../../base/ErgeonLink';
import usePhoneFormatter from '../../utils/usePhoneFormatter';

import IconPhone from '!@svgr/webpack!../../../assets/icon-phone-green.svg';
import IconAddress from '!@svgr/webpack!../../../assets/icon-address.svg';
import iconVerified from '../../../assets/icon-verified-small.svg';

import type { SimpleFooterProps } from './types';

type DetailsFooter = Pick<
  SimpleFooterProps,
  'phoneNumber' | 'address' | 'licenses'
>;

const DetailsFooter = ({ address, licenses, phoneNumber }: DetailsFooter) => {
  const phoneFormatter = usePhoneFormatter(phoneNumber);

  return (
    <div className="SimpleFooter-Column">
      {phoneNumber && (
        <div className="IconWithText">
          <ErgeonLink className="flex-row align-center link is-withoutOffset" href={phoneFormatter.link}>
            <IconPhone />
            <b>{phoneFormatter.display}</b>
          </ErgeonLink>
        </div>
      )}
      {address && (
        <div className="IconWithText">
          <IconAddress />
          <address className="display-flex flex-spacer flex-wrap">
            {address.split(',').map((addressPart, idx, { length }) => (
              <span className="nowrap-text" key={idx}><span>{addressPart}</span>{idx !== length - 1 && <>,&nbsp;</>}</span>
            ))}
          </address>
        </div>
      )}
      {address && (
        <p className="fence-deliveryNotice">
          <i>&#42;Delivery times may vary due to demand spike</i>
        </p>
      )}
      <div className="is-Licenses">
        {licenses?.length &&
          licenses.map((license, index) => (
            <div key={`license-${index}`} className="IconWithText LicenseWrapper">
              <ReactSVG className="is-LicenseIcon" src={iconVerified} />
              {license?.url ? (
                <div>
                  {license.label && (
                    <span className="LicenseLabel">{`${license.label}:`}</span>
                  )}
                  <ErgeonLink className="LicenseWithLink" href={license.url}>
                    {license.name}
                  </ErgeonLink>
                </div>
              ) : (
                <div>
                  {license.label && (
                    <span className="LicenseLabel">{`${license.label}:`}</span>
                  )}
                  <span className="LicenseWithLink">{license.name}</span>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
}

export default DetailsFooter;
