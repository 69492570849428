import React, { useCallback, useState } from 'react';

import { Button } from '@ergeon/core-components';
import classNames from 'classnames';
import './index.scss';

const HelpSearchField = (props) => {
  const { className, onChange, onSubmit } = props;

  const [inputValue, setInputValue] = useState('');

  const onChangeValue = useCallback(
    (event) => {
      const input = event.target.value;
      setInputValue(input);

      onChange?.(input);
    },
    [onChange],
  );

  const onSubmitInput = useCallback(
    (event) => {
      event.preventDefault();
      onSubmit?.(inputValue);
    },
    [onSubmit, inputValue],
  );

  return (
    <form className={classNames('NewSearchfield', className)} onSubmit={onSubmitInput}>
      <input
        className="NewSearchfield-input"
        onChange={onChangeValue}
        placeholder="Ask us anything..."
        value={inputValue}
      />
      <Button className="NewSearchfield-button">Ask</Button>
    </form>
  );
};

export default HelpSearchField;
