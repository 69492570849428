import React, {useEffect, useMemo, useState} from 'react';

import Tabs from '../../Tabs';
import SpecificationTable from '../index';

import './index.scss';

export interface ProductConfigTableRow {
  image_thumbnail?: string;
  image?: string;
  subtitle?: string;
  title: string;
}

export interface SpecificationTableTab {
  id: string;
  label: string;
  attrs: ProductConfigTableRow[];
}

interface SpecificationTableWithTabsProps {
  tabs: SpecificationTableTab[];
}

const SpecificationTableWithTabs = ({tabs}: SpecificationTableWithTabsProps) => {
  const [activeTabId, setActiveTabId] = useState<string | undefined>(tabs[0]?.id);

  const tabItems = useMemo(
    () =>
      tabs.map((tab) => ({
        id: tab.id,
        content: tab.label,
        active: activeTabId === tab.id,
        onClick: () => setActiveTabId(tab.id),
      })),
    [tabs, activeTabId],
  );

  const activeTab = useMemo(() => tabs.find((tab) => tab.id === activeTabId), [tabs, activeTabId]);

  useEffect(
    function resetActiveTabId() {
      if (!activeTab) {
        setActiveTabId(tabs[0]?.id);
      }
    },
    [activeTab, tabs],
  );

  return (
    <div className="specification-table-with-tabs">
      {tabs.length > 1 && <Tabs items={tabItems} />}
      {activeTab && <SpecificationTable attrs={activeTab.attrs} />}
    </div>
  );
};

export default SpecificationTableWithTabs;
