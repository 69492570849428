import React from 'react';

import PropTypes from 'prop-types';
import * as I from 'immutable';
// eslint-disable-next-line import/no-unresolved
import { Spinner, TopPanel, Footer } from '@ergeon/core-components';

// eslint-disable-next-line import/no-named-as-default
import authService from 'common/auth';
import './index.scss';

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authError: null,
      authLoading: false,
      authData: null,
    };
  }

  componentDidMount() {
    authService.on('refresh', this.handleRefresh.bind(this));
    this.getUser();
  }

  componentDidUpdate(_, nextState) {
    const { authData, authError } = this.state;
    const { authData: nextAuthData } = nextState;
    if (!authData && !nextAuthData && authError && authError.reason === 'missing-token') {
      authService.authenticate();
    }
  }

  componentWillUnmount() {
    authService.off('refresh');
  }

  handleRefresh() {
    this.getUser();
  }

  handleLogIn() {
    this.setState({
      authLoading: true,
    });
    authService.authenticate();
  }

  getUser() {
    this.setState({
      authLoading: true,
    });
    authService.getUser((authError, authData) => {
      this.setState({
        authError,
        authData: authData ? I.Map(authData) : null,
        authLoading: false,
      });
    });
  }

  renderAuth() {
    const SPINNER_BORDER_WITH = 0.10;
    const SPINNER_SIZE = 64;
    const { authLoading } = this.state;
    return (
      <div className="help-senter__auth wrapper-1180">
        <h1>Oops, Looks like you are not logged in</h1>
        <h2>
          <a onClick={this.handleLogIn.bind(this)} style={{ cursor: 'pointer' }}>
            Login with Ergeon Account
          </a>
        </h2>
        <Spinner
          active={authLoading}
          borderWidth={SPINNER_BORDER_WITH}
          color="blue"
          size={SPINNER_SIZE} />
      </div>
    );
  }

  renderContent() {
    const { children, ...rest } = this.props;
    return (
      <div className="help-senter__content">
        {React.cloneElement(children, rest)}
      </div>
    );
  }

  render() {
    const { authData } = this.state;
    const isLoggedIn = Boolean(authData);
    const websiteDomain = process.env.WEBSITE_DOMAIN;
    return (
      <div className="help-senter">
        <TopPanel
          ergeonUrl={websiteDomain}
          pdfMode />
        {!isLoggedIn && this.renderAuth()}
        {isLoggedIn && this.renderContent()}
        <Footer appEnv={process.env.NODE_ENV} ergeonUrl={websiteDomain} />
      </div>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.any,
};

export default Layout;
