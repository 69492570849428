import _HelpLandingPage from 'components/HelpLandingPage';
import _HelpLandingCustomerPage from 'components/HelpLandingCustomerPage';
import _HelpPage from 'components/HelpPage';
import _HelpAIPage from 'components/HelpAIPage';
import _HelpSearchField from 'components/HelpSearchField';
import _HelpSearchResults from 'components/HelpSearchResults';

export const HelpLandingCustomerPage = _HelpLandingCustomerPage;
export const HelpLandingPage = _HelpLandingPage;
export const HelpPage = _HelpPage;
export const HelpAIPage = _HelpAIPage;
export const HelpSearchField = _HelpSearchField;
export const HelpSearchResults = _HelpSearchResults;
